<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
<!--              <a-form-item label="徽章分类名称" prop="typeName">-->
<!--                <a-input v-model="queryParam.typeName" placeholder="请输入徽章分类名称" allow-clear />-->
<!--              </a-form-item>-->
              <a-form-model-item label="徽章分类" prop="badgeTypeId">
                <a-select v-model="queryParam.badgeTypeId" style="width: 100%" placeholder="请选择背景" :options="ConfigBadgeTypeOptions"
                          @change="selectFilterConfigBadgeTypeChange"></a-select>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="徽章名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入徽章名称" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="徽章级别" prop="level">
                  <a-input v-model="queryParam.level" placeholder="请输入徽章级别" allow-clear />
                </a-form-item>
              </a-col>
              <!--              <a-col :md="8" :sm="24">
                <a-form-item label="未点亮图片" prop="notLightImg">
                  <a-input v-model="queryParam.notLightImg" placeholder="请输入未点亮图片" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="已点亮图片" prop="lightImg">
                  <a-input v-model="queryParam.lightImg" placeholder="请输入已点亮图片" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="点亮人数" prop="lightNum">
                  <a-input v-model="queryParam.lightNum" placeholder="请输入点亮人数" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="虚拟点亮人数" prop="lightNumVirtual">
                  <a-input v-model="queryParam.lightNumVirtual" placeholder="请输入虚拟点亮人数" allow-clear/>
                </a-form-item>
              </a-col>-->
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['config:badge:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['config:badge:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['config:badge:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['config:badge:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns"
          :refresh-loading="loading" @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
        :bordered="tableBordered">
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.userBadgeList.open(record.id)" v-hasPermi="['config:appellation:edit']">
            <a-icon type="user" />发放用户
          </a>
          <a-divider type="vertical" v-hasPermi="['config:badge:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['config:badge:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['config:badge:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['config:badge:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
        <span slot="alone" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（标签）-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.alone"/>
        </span>
        <span slot="top" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（标签）-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.top"/>
        </span>
        <template slot="lightImg" slot-scope="text, record">
          <div>
            <img v-if="record.lightImg" :src="record.lightImg" style="width:60px;height:62px;" />
          </div>
        </template>
        <template slot="isOut" slot-scope="text, record">
          <!-- 重写自定义了个字典组件（标签）-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isOut" />
        </template>
        <template slot="notLightImg" slot-scope="text, record">
          <div>
            <!--            <img v-for="imgurl in record.notLightImg.split(',')"
                 v-if="record.notLightImg" :src="imgurl"  style="width:60px;height:62px;"/>-->
            <img v-if="record.notLightImg" :src="record.notLightImg" style="width:60px;height:62px;" />
          </div>
        </template>

      </a-table>
      <!-- 分页 -->
      <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
        :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange" @change="changeSize" />
    </a-card>
    <user-badge-list ref="userBadgeList"></user-badge-list>
  </page-header-wrapper>
</template>

<script>
import {listBadge, delBadge, loadFilterOptions} from '@/api/config/badge'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import { mapGetters } from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import UserBadgeList from './modules/UserBadgeList.vue';

export default {
  name: 'Badge',
  components: {
    CreateForm,
    CustomDictTag,
    UserBadgeList
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      ConfigBadgeTypeOptions: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        badgeTypeId: null,
        sort: null,
        typeName: null,
        isOut: null,
        name: null,
        level: null,
        notLightImg: null,
        lightImg: null,
        lightNum: null,
        lightNumVirtual: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: 'id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '徽章分类id',
          dataIndex: 'badgeTypeId',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '徽章分类名称',
          dataIndex: 'typeName',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '徽章名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '徽章级别',
          dataIndex: 'level',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '未点亮图片',
          dataIndex: 'notLightImg',
          scopedSlots: { customRender: 'notLightImg' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '已点亮图片',
          dataIndex: 'lightImg',
          ellipsis: true,
          scopedSlots: { customRender: 'lightImg' },
          align: 'center'
        },
        {
          title: '点亮人数',
          dataIndex: 'lightNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '虚拟点亮人数',
          dataIndex: 'lightNumVirtual',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否绝版',
          dataIndex: 'isOut',
          scopedSlots: { customRender: 'isOut' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否顶级',
          dataIndex: 'top',
          scopedSlots: {customRender: 'top'},
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '是否单独称号',
        //   dataIndex: 'alone',
        //   scopedSlots: {customRender: 'alone'},
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '升级组局数量',
          dataIndex: 'groupNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },

        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
    this.initConfigBadgeTypeOptions()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询徽章配置列表 */
    getList() {
      this.loading = true
      listBadge(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        badgeTypeId: undefined,
        sort: undefined,
        typeName: undefined,
        name: undefined,
        level: undefined,
        notLightImg: undefined,
        lightImg: undefined,
        lightNum: undefined,
        lightNumVirtual: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    selectFilterConfigBadgeTypeChange(value) {
      this.form.badgeTypeId = value
    },
    initConfigBadgeTypeOptions() {
      loadFilterOptions({ type: 4 }).then(res => {
        this.ConfigBadgeTypeOptions = res.data
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delBadge(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() { }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('config/badge/export', {
            ...that.queryParam
          }, `badge_${new Date().getTime()}.xlsx`)
        },
        onCancel() { }
      })
    }
  }
}
</script>
